@import "./media-queries.scss";
.aneesWork {
  padding: 70px 0;
  background-color: #000;
  @include lt-lg {
    padding: 20px 0;
  }
  @include lt-md {
    padding: 5px 0;
  }
  .iconToggleView {
    position: absolute;
    right: calc(50% - 18px);
    top: 53px;
    background: #555555;
    padding: 9px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 100px;
    z-index: 2;
    cursor: pointer;
    transform: scale(1);
    transition: all ease 0.6s;
    img {
      transform: scale(0.9);
      transition: all ease 0.3s;
    }
    &:active {
      transform: scale(1.3);
      img {
        transform: scale(1.2);
      }
    }
  }
  @keyframes slide-top {
    0% {
              transform: translateY(0);
    }
    100% {
              transform: translateY(-75px);
    }
  }
  .detailPage1 {
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    background-color: #fff;
    color: #253d43;
    line-height: 1.5em;
    padding: 90px 20px 20px;
    width: 100%;
    transition: all ease 0.4s;
    @include gt-sm {
      padding-top: 60px;
    }
    .detailPage1Item{
      position: relative;
      padding: 5% 5%;
      width: 100%;
      align-items: start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    @include gt-sm {
      flex-direction: row;
    }
    .picPan{
      width:100%;
      padding: 10px 0;
      position: relative;
      @include gt-sm {
        width:calc(40% - 20px);
      }

      .workPic {
        height: auto;
        border: 1px solid #d3cccc;
        width: 100%;
      }
    }
    .textPan{
      width: 100%;
      @include gt-sm {
        width: calc(60% - 20px);
      }
      h2{
        font-size: 2.5rem;
        margin-bottom: 15px;
      }
      h3{
        margin-bottom: 15px;
      }
    }
   .logo{
    position: absolute;
    right: 1px;
    top: 11px;
    max-width: 20%;
    padding: 6px 12px;
    background: #fff;
    box-shadow: -2px 2px 4px 0px #00000042;
    max-height: 20%;
    opacity: 0.3;
    transition: all ease 0.3s;
   }
   &:hover {
    .logo{
      opacity: 1;
    }
   }
  }
  hr {
    width: 95%;
    margin: 10px auto !important;
 }
 .details{
  padding-top: 30px;
 }
  }
  // .picPan{
  //   width: 40%;
  //   background-color: white;
  //   padding: 20px;
  //   box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  // }
  // .textPan {
  //   width: 60%;
  //   background-color: #1c305e; // Adjust the color to match your design
  //   padding: 20px;
  //   color: white;
  //   padding:0 100px;
  // }
  // .img {
  //   max-width: 100%;
  //   width: 100%;
  // }
  // .strngText {
  //   margin: 0px 0 50px;
  //   border: 1px solid rgba(0, 0, 0, 0.06);
  //   padding: 30px;
  //   background: rgba(204, 204, 204, 0.07);
  // }
  // h3{
  //   margin-bottom: .5rem;
  //   font-weight: 500;
  //   line-height: 1.2;
  // }
  // p{
  //   padding-bottom: 50px;
  //   border-bottom: 1px solid #ccc;
  // }
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}
ul.logoList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  @include lt-lg {
    padding: 0;
  }
}



@keyframes myAnim1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes myAnim2 {
  0% {
    max-height: 0;
    max-width: 0;
    opacity: 0;
  }

  100% {
    max-height: 100px;
    max-width: 220px;
    opacity: 1;
  }
}

ul.logoList li {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  border: 1px solid rgb(204 204 204 / 25%);
  margin: 14px;
  background-color: #fff;
  animation: myAnim1 0.4s ease 0s 1 normal forwards;
  opacity: 0;
  min-width: 355px;
  align-items: center;
  justify-content: center;
  @include lt-lg {
    width: calc(50% - 10px);
    margin: 5px;
    min-height: 150px;
    min-width: auto;
  }
  @include lt-sm {
    margin: 1px;
  }
  &:hover {
    opacity: 1;
  }
}
ul.logoList .logoItem {
  transition: all ease 0.4s;
  transform: scale3d(1, 1, 1);
  &:hover {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 40px 46px;
    @include lt-sm {
      padding: 40px 46px;
    }
  }
}
ul.logoList .logoItem img {
  max-height: 100px;
  max-width: 220px;
  filter: grayscale(100%);
  transition: all ease 0.1s;
  animation: myAnim2 0.1s ease 0s 1 normal forwards;
  &:hover {
    filter: grayscale(0%);
  }
  @include lt-lg {
    max-width: 100%;
    width: 100%;
    height: auto;
    padding: 0;
  }
}
.talePara {
  color: #ccc;
  text-align: center;
  padding: 0 15px;
  a {
    color: #ccc;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
// show work
