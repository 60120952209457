.container2 {
  background-color: #e6f2ff;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 70px 0 100px;

  .cta-button {
    border-radius: 0;
    height: auto;
    height: 46px;
    margin-top: 5px;

  }
  .red {
    color: red;
    font-size: 10px;
  }
  .left-column {
    padding: 0 32px;
    width: 300px;
  }
  input {
    border: 2px solid black;
  }
  .center-column {
    width: calc(100% - 300px);
  }

  input,
  textarea {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 5px;
    border: 1px solid black;
  }
  .react-tel-input .form-control {
    width: calc(100% - 20px);
  }
  form {
    margin: -90px 40px 0;
  }
  textarea {
    resize: vertical;
    height: 100%;
  }
  .textarea
  {
    height: 100px !important;
    margin-bottom: calc(1rem + 5px);
  }
  .form-row {
    display: flex;
    justify-content: space-between;
    margin: 0;
    @media only screen and (min-width: 800px) {
      gap: 1em;

    }
  }
  .phone-input {
    width: 50%;
  }

  .form-row .form-group {
    width: 48%;
    margin-bottom: 5px;
  }
  .contact-info {
    margin-bottom: 0px;
    padding-left: 0px;
  }
  .image {
    max-width: 100%;
  }
  .text-with-svg {
    display: flex;
    color: black;
    align-items: start;
    margin-top: 30px;
  }
  .text-with-svg svg {
    width: 34px; /* Adjust size as needed */
    height: 24px; /* Adjust size as needed */
    margin-right: 5px; /* Adjust spacing as needed */
  }

  .text-content {
    display: inline-block;
  }
  .text-content h3 {
    margin: 5px;
    color: white; /* Remove default margin */
  }

  .text-content p a {
    color: #007bff; /* Remove default margin */
  }
  .block a {
    color: #007bff;
  }
  .text-content2 p a {
    color: #007bff; /* Remove default margin */
  }
  .text-content3 a {
    color: #007bff; /* Remove default margin */
  }
  .text-content2 a {
    color: #007bff; /* Remove default margin */
  }

  .new-class-name {
    display: inline-flex;
    align-items: center;
  }
  .new-class-name svg {
    color: red;
    width: 24px; /* Adjust size as needed */
    height: 24px; /* Adjust size as needed */
    margin-right: 3px;
    margin-top: 18px; /* Adjust spacing as needed */
  }
  .text-content2 {
    display: inline-block;
    margin-top: 18px; /* Adjust top margin as needed */
  }
  .text-content2 h3 {
    margin: 5px; /* Adjust margin as needed */
  }
  .text-content2 p {
    margin: 0; /* Remove default margin */
  }
  .new-class-name2 {
    display: inline-flex;
    align-items: center;
  }
  .new-class-name2 svg {
    color: red;
    width: 24px; /* Adjust size as needed */
    height: 24px; /* Adjust size as needed */
    margin-right: 3px;
    /* Adjust spacing as needed */
  }
  .text-content3 {
    display: inline-block;
    /* Adjust top margin as needed */
  }
  .text-content3 h3 {
    margin: 5px;
    /* Adjust margin as needed */
  }
  .text-content3 p {
    margin: 0; /* Remove default margin */
  }
  .react-tel-input {
    height: 46px;
    margin-top: 5px;
  }
  .react-tel-input .flag-dropdown {
    border-color: #000;
    border-radius: 0;
  }
  .react-tel-input .form-control {
    border-color: #000;
    border-radius: 0;
  }
  .react-tel-input .form-control {
    height: 46px;
  }
  .image {
    width: 100%;
    height: 420px;
    object-fit: cover;
  }
  .center-columns {
    width: calc(100% - 300px);
  }
}
/* Media queries for responsiveness */

/* Phone (small screens) */
@media only screen and (max-width: 800px) {
  .container2 {
    flex-direction: column-reverse;
    padding: 0 0 80px;
    width: 100%;
    .center-column,
    .left-column {
      width: 100%;
    }
    .center-columns,
    input,
    textarea,
    .react-tel-input .form-control,
    .form-row .form-group {
      width: 100%;
    }
    form {
      margin: -140px 5% 0;
    }
  }
}
