@import "./media-queries.scss";
.home {
  h2 {
    color: #5a4c8b;
    font-family: "PT Serif";
    font-weight: bold;
    font-size: 2rem;
  }

  .picPan {
    margin: 65px;
    border: 1px solid #333;
    background: #fff;
    display: none;
  }

  .picPan img {
    max-width: 100%;
    padding: 45px;
    box-shadow: 0.5rem 0.5rem #3f3f3f, -0.5rem -0.5rem #ccc;
    /* filter: grayscale(100%); */
  }

  .heroCards {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .heroCards__list {
    display: flex;
    flex-wrap: nowrap;
    width: max-content;
  }

  .heroCards__item {
    width: 78vw;
    height: 100vh;
    @include gt-sm {
      width: 35vw;
    }
  }

  .heroCards__item.bgOne {
    width: 55vw;
    background-color: #f2f6fd;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    background-position: center;
  }

  .heroCards__item.about {
    width: 78vw;
    @include gt-sm {
      width: 55vw;
    }
    a {
      color: #000;
    }
  }

  h1 {
    font-family: "Oxygen", sans-serif;
    font-size: 1.2rem;
    color: #5a6f8e;
    @include gt-sm {
      font-size: 2rem;
    }
  }

  h1 a {
    color: #5a6f8e;
    text-decoration: none;
  }

  .logo,
  .about,
  .textPan {
    padding: 20px 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    @include xs {
      padding: 20px 60px 20px 25px;
    }
  }

  .textPan {
    box-shadow: inset -7rem -6rem 20px 10px #f2f6fd;
    background: #f9fbff;
    position: relative;
    h2,
    p {
      z-index: 2;
    }
  }
  .scroll .logo {
    padding: 20px;
  }

  .logo {
    align-items: center;
    font-size: 1.2rem;
    font-family: "PT Serif", serif;
    background: #000;
    color: #fff;
    position: sticky;
    left: 0;
    transition: all ease 0.76s;
    flex-direction: row;
    width: 85vw;
    z-index: 10;
    @include gt-sm {
      font-size: 2.8rem;
    }
    @include gt-sm {
      width: 45vw;
    }
  }

  .scroll .logo {
    width: 10vw;
  }

  .logo .hide {
    transition: all ease 1.4s;
    display: inline;
  }

  .scroll .logo .hide {
    font-size: 0rem;
  }

  .logo a {
    font-size: 2.6rem;
    color: #999;
  }
  .scroll .logo a {
    font-size: 1.7rem;
    @include lt-sm {
      font-size: 2.2rem;
      word-break: break-all;
    }
  }

  a:visited,
  .cta {
    color: #999;
    font-size: 0.9rem;
  }
  .cta:hover {
    text-decoration: none;
  }
  .logo a:hover {
    color: #fff;
  }
  margin-top: -76px;
  .cta a {
    background: rgb(145 145 145 / 100%);
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    transition: all ease 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 166px;
    &:hover {
      background-color: #000;
    }
  }
}
.home ~ .navigation {
  margin-top: -100px;
}

.home ~ .navigation ~ .footer {
  display: none;
}
@include xs {
  .home .logo {
    width: 100vw;
    height: 60vh;
    position: fixed;
    align-items: end;
    a {
      position: relative;
      z-index: 2;
    }
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #272727 url(../assets/beard-and-glasses.png) no-repeat 50% -20%;
      background-size: inherit;
      opacity: 0.5;
      z-index: 1;
    }
  }
  .home .scroll .logo {
    width: 100vw;
    height: 100px;
    background-color: #000;
  }
  .home .heroCards__item.about {
    width: 100vw;
    padding: 50vh 50px 20px;
  }
  .home .textPan {
    padding-top: 120px;
  }
  .home .textPan h2, .home .textPan p {
    border-top: 2px solid #e8e8e8;
    padding-top: 10px;
  }
  .home a:visited, .home .cta {
      border-bottom: 2px solid #e8e8e8;
      padding-bottom: 15px;
  }
  .home .heroCards__item.bgOne {
    background-size: contain;
    background-position: center bottom;
    height: calc(70vh - 100px);
    border: 2px solid #ebebeb;
  }
}
@media screen and (max-width: 375px) {
  .home .heroCards__item.about {
    padding-top: 60vh;
  }
  .home .logo .hide {
    font-size: 2.2rem;
  }
}
