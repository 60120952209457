/* Business container styles */
.business-container {
  width: 100%;
  max-width: 800px;
  margin: 110px auto 40px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 400px;
  background-color: #edeeff;
  display: flex;
  flex-direction: column;

  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  /* Button styling */
  button {
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 0.6em 1.2em;
    font-size: 1em;
    font-weight: 500;
    background-color: #1a1a1a;
    cursor: pointer;
    transition: border-color 0.25s;
    color: white;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0 0;
    background-color: #333;
    @media (min-width: 768px) {
      width: auto;
      padding: 10px 20px;
    }
  }
  button.afterloginBtn {
    width: auto;
    padding: 0.4em 1em;
  }
  button.addedBtn {
    margin-left: 10px;
    background-color: #e51c25;
  }
  button.btnSubmit {
    width: 100%;
  }

  button:focus,
  button:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
  }

  /* Authentication section */
  .authentication-section {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 280px;
  }

  .authentication-section h2 {
    font-size: 23px;
    color: #333;
  }

  /* Search and data list */
  .search-section {
    margin: 0 0 10px;
  }

  .data-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .data-item {
    margin: 10px 0;
    padding: 20px;
    border: 1px solid #d8d8d8;
  }
  li.data-item:nth-child(odd) {
    background-color: #fff;
  }
  li.data-item:nth-child(even) {
    background-color: #ffe7e8;
  }

  .data-label {
    font-weight: bold;
  }

  @media (min-width: 1024px) {
    width: 70vw;
    padding: 60px;
  }

  @media (min-width: 0px) and (max-width: 768px) {
    margin: 50px auto 0;
  }
}
