@import "./media-queries.scss";

.aboutAnees {
  background-color: #000;
  overflow: hidden;
  padding: 0 0 120px;
  font-size: 1.2rem;
  @include gt-sm {
    padding: 0;
  }
  .abLink {
    display: inline;
    color: #fff;
  }

  .container {
    color: #fff;
    transition: all ease 0.4s;
    min-height: 0;
  }
  .flex {
    display: flex;
    @include gt-sm {
      min-height: 1450px;
    }
  }

  .flex img {
    max-width: 100%;
  }

  .aboutPicPan {
    position: absolute;
    right: 0;
    width: 40%;
    filter: blur(4px);
    @include gt-sm {
      width: 50%;
      position: relative;
      background: url(./../assets/anees-IMG_4.jpeg) no-repeat;
      background-attachment: fixed;
      background-size: 40%;
      background-position: right top;
      opacity: 0.25;
    }
  }
  @keyframes myAnim3 {
    0% {
      transform: translate(100px, -100px);
    }

    100% {
      transform: translate(0px, 0);
    }
  }
  .aboutPicPan img {
    position: absolute;
    width: 40vw;
    z-index: -1;
    opacity: 0.2;
    animation: myAnim3 0.2s ease;
    @include gt-sm {
      opacity: 0;
      animation: myAnim3 2s ease;
    }
  }
  @keyframes myAnim {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .aboutTextPan {
    animation: myAnim 2s ease 0s 1 normal forwards;
    width: 100%;
    @include gt-sm {
      width: 50%;
    }
  }

  .textInner {
    max-width: 600px;
    margin: 110px 0 0;
    @include gt-sm {
      margin: 100px 0 50px;
    }
  }

  h1 {
    font-family: "PT Serif", serif;
    font-size: 2rem;
    margin: 0 0 20px;
  }

  p {
    line-height: 1.5;
    margin-bottom: 2rem;
    color: rgba(255, 255, 255, 0.695);
  }
  @include lt-md {
    .textInner {
      margin: 30px 0 0;
    }
    .aboutPicPan {
      filter: blur(1px);
      z-index: 0;
    }
    .aboutTextPan {
      width: 72%;
      position: relative;
      z-index: 2;
    }
  }
}
