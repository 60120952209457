@import "@fontsource/oxygen";
@import "@fontsource/pt-serif";
@import "./style/home.scss";
@import "./style/media-queries.scss";

body {
  margin: 65px 0 0;
  font-family: "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

h1 {
  font-weight: bold;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}

.navigation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  transition: all ease 0.4s;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}
.linkAsk {
  font-family: "PT Serif";
  font-weight: bold;
  font-size: 2rem;
  padding: 0 12px 0 0 !important;
  border-right: 1px solid #e6e6e6;
  margin-right: 5px;
}
.navbar-expand .navbar-nav {
  margin-left: -10px;
}
li.nav-item {
  display: flex;
  align-items: center;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.8);
}
.navbar-brand img {
  width: 32px;
}
a.ic-ln {
  background: #f0f0f0;
  padding: 3px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
a.ic-ln svg {
  width: 20px;
}
.footer {
  position: relative;
  z-index: 4;
}

.noPage {
  min-height: calc(100vh - 185px);
  h2 {
    padding-top: 30vh;
    display: block;
    font-size: 3rem;
    font-family: "PT Serif";
    color: red;
  }
}

.workPan {
  min-height: 400px;
}

.scrollPage {
  position: fixed;
  right: 10px;
  bottom: 50px;
  z-index: 10;
  font-size: 1rem;
  .scrollPageInner {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 2s;
    opacity: 1;
  }
  i {
    color: #9d9d9d;
  }
  .arrowWrapper {
    display: flex;
  }
  .arrow {
    display: flex;
    padding: 10px;
    animation: bounce 0.4s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
  .arrow-head {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid black;
  }
  .down-arrow-head {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid black;
  }
  .arrow-body {
    height: 2px;
    width: 20px;
    background-color: black;
    margin-top: 3px;
  }

  @keyframes bounce {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(10px, 0px, 0px);
    }
  }
}
.scroll .scrollPageInner {
  opacity: 0;
}
button:focus {
  outline: none;
}
.homeContainer ul {
  display: flex;
  list-style: none;
  button {
    padding: 5px 10px;
    font-size: 12px;
    color: inherit;
    border: 0;
  }
}
.arroPan {
  transform: rotate(-90deg);
  margin: 0 20px;

  .arrow,
  .arrow:before {
    position: absolute;
    left: 50%;
  }

  .arrow {
    width: 40px;
    height: 40px;
    top: 50%;
    margin: -20px 0 0 -20px;
    transform: rotate(45deg);
    border-left: none;
    border-top: none;
    border-right: 2px #fff solid;
    border-bottom: 2px #fff solid;
  }

  .arrow:before {
    content: "";
    width: 20px;
    height: 20px;
    top: 50%;
    margin: -10px 0 0 -10px;
    border-left: none;
    border-top: none;
    border-right: 1px #fff solid;
    border-bottom: 1px #fff solid;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: arrow;
  }

  @keyframes arrow {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(-10px, -10px);
    }
  }
}
