@import "./media-queries.scss";
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 1;
  stroke-miterlimit: 10;
  // stroke: #cfcfcf5f;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  stroke-width: 1;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px rgb(164 164 164 / 38%);
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 29;
  stroke-dashoffset: 29;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7c7c7c;
  }
}
@keyframes myAnim {
  0% {
    background-size: 20%;
  }

  100% {
    background-size: 8%;
  }
}
.showWorkData {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  transition: all ease 0.2s;
  padding: 0 50px;
  padding: 0 0px 0 40vw;
  right: 0;
  z-index: 11;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 60px calc(100% - 60px);
  animation: myAnim 0.4s ease 0s 1 normal forwards;
  @include lt-lg {
    padding: 0;
  }
}
.showWorkOff .showWorkData {
  background-color: transparent;
}
.showWorkOff .showWorkData .inner {
  @keyframes myAnim2 {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(110%);
    }
  }
  animation: myAnim2 0.2s ease-out 0s 1 normal forwards;
}
@keyframes myAnim1 {
  0% {
    transform: translateX(110%);
  }

  100% {
    transform: translateX(0%);
  }
}
.showWorkData .inner {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #393939;
  transition: all ease 0.5s;
  border-left: 1px solid #dcdcdc;
  padding-left: 100px;
  padding-right: 100px;
  animation: myAnim1 0.4s ease 0s 1 normal forwards;
  color: #fff;
  @include lt-lg {
    padding: 0 20px;
    border: 0;
  }
}
.showWorkData .inner a {
  color: #fff;
}
h2.subTitle {
  margin: 35px 0 20px;
  display: flex;
  justify-content: space-between;
  @include lt-lg {
    margin: 15px 30px 25px 0;
  }
}
.iconClose {
  cursor: pointer;
  @include lt-lg {
    position: absolute;
    right: 15px;
    top: 0px;
  }
}
.breadCrumbs {
  margin: 0 0 10px;
  font-size: 0.8rem;
}

.showWorkData img {
  max-width: 100%;
  width: 100%;
}
.usedSkills {
  margin: 0px 0 50px;
  border: 1px solid rgb(0 0 0 / 0.06);
  padding: 30px;
  background: rgba(204, 204, 204, 0.07);
  @include lt-lg {
    padding: 15px;
  }
}
.usedSkills li {
  padding: 0 0 10px;
}
h2.headPara {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 50px;
  @include lt-lg {
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
}
.workPicPanel {
  border: 16px solid rgb(0 0 0 / 1);
}
.workDataTale {
  border-top: 1px solid #ccc;
  padding: 35px 0 60px;
  margin-top: 50px;
  display: flex;
}
.workDataTale a {
  margin-right: 30px;
  background: rgb(33, 33, 33);
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  transition: all ease 0.4s;
  &:hover {
    background-color: #515151;
  }
  @include lt-lg {
    padding: 10px 20px;
    margin-right: 15px;
    font-size: 0.6rem;
  }
}
.footer {
  z-index: 4;
}